.model-toolbox-empty-contener {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.toolbox-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  width: 200px;
  height: 200px;
  position: relative;
}

.toolbox-empty p {
  font-size: 20px;
}

.toolbox-empty h3 {
  font-weight: 800;
  color: #000000;
  font-size: 18px;
}

.tooltip-model {
  position: fixed; 
  font-size: 12px;

  background-color: #000000db;
  color: #ffffff;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  pointer-events: none;
  max-width: 200px;
  white-space: normal; 
}