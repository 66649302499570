.side-menu {
    opacity: 0;
    animation: fadeIn 1.5s forwards;
    margin-bottom: 40px;
}



.link-nav-side-menu {
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: flex-start;  
    padding:15px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 300px;
    border-radius: 3px;
    border: 1px solid black;
    transition: transform 200ms ease;
    
}


.link-nav-side-menu.active {
    background-color: #000000; 
    
}

.link-nav-side-menu:hover {
    box-shadow: 5px 5px 1px rgba(0, 0, 0, 0.072); 
    transform: translateY(-5px);
}

.txt-side-nav {
    font-family: 'Inter';
    font-weight: 800;
    color: rgb(0, 0, 0);
    font-size: 11px;
    text-transform: uppercase;
    margin-left: 10px;

}

.txt-side-nav.active {
    color: #ffffff; 
}

.txt-side-nav.disable {
    border: 0px;
    color: #d0d0d0; 

}

.link-nav-side-menu.disable:hover {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.072);
    transform: translateY(0px);
}


.icon-nav-side {
    margin-right: 12px;
}

.icon-side-menu.active {
    color: #ffffff; 
}

.icon-side-menu.disable {
    border: 0px;
    color: #d0d0d0; 
}

.disable {
    border: 1px solid rgba(182, 182, 182, 0.276);
    background-color: #f6f6f6d2;
}

.disable:hover {
    transform: none;
}

.icon-side-menu {
    color: rgb(0, 0, 0);
    width: 18px;
    
}

.icon-side-menu.white {
    color: #d0d0d0;
}

.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    padding: 8px 12px;
    border-radius: 2px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 99;
    transform: translateY(-50%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

//  ====================== RESPONSIVE AREA ===============================
//  ======================+++++++++++++++++===============================


@media screen and (min-width: 1440px) {
    .link-nav-side-menu {
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (max-width: 991px) {
    .tooltip {
        white-space: normal;
        max-width: 150px;
        overflow-wrap: break-word;
    }
    
    .side-menu.collapsed {
        display: none;
    }

}

