.post-card {
    border: 1px solid #e9e9e9;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    max-width: 400px;
    background-color: white;
}

.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 15px;
    background-color: transparent;
    border-bottom: 1px solid #e9e9e9;
}

.infos-panel {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    font-family: Inter;
    font-weight: 400;
    font-size: 15px;

}

.card-img-frame img {
    border-radius: 50%;
    width: 35px;
    height: auto;
    margin-right: 10px;
    object-fit: cover;
}

.card-infos {
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 40px;

}

.card-infos p {
    text-align: center;
    width: 100%;
}


.tags-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
}


.icon-fav-social-card {
    color: black;
    margin-left: 10px;
    width: 22px;
    height: 35px;

}



@mixin custom-tag-button($background-color, $text-color) {
    background-color: $background-color;
    border: none;
    color: $text-color;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    margin: 2px;
    cursor: pointer;
    border-radius: 2px;
    padding: 5px 12px;
    text-transform: uppercase;
}

.tag-button {
    @include custom-tag-button(rgb(251, 8, 68), white);
    font-size: 8px;
}

.tag-button-content_type {
    @include custom-tag-button(rgb(252, 5, 58), white);
}

.tag-button-content_sub_type {
    @include custom-tag-button(rgb(242, 183, 75), white);
}

.tag-button-emotions {
    @include custom-tag-button(rgb(204, 104, 247), white);
}

.card-btn {
    width: 100%;
    margin-top: 20px;
    background-color: transparent;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 12px;

    text-transform: uppercase;
    border: 1px solid black;
    padding: 10px;
    /* Vous pouvez ajuster la valeur de padding selon vos besoins */
}