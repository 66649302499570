.sw-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    overflow: hidden;
}

.sw-container:hover {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.05);
}

.sw-nav {
    background: #fdfdfd;
    padding: 5px 5px 0;
    border-bottom: 1px solid #eeeeee;
    height: 44px;
}

.sw-ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: nowrap;
}

.sw-li {
    list-style: none;
    font-family: "Inter";
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 15px;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: white;
    cursor: pointer;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    min-width: 0;
    position: relative;
    user-select: none;
}

.sw-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    user-select: none;
    padding: 60px;
    padding-left: 80px;
    padding-right: 80px;
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: justify;
    
}

.sw-underline {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 3px;
    background: rgb(241, 16, 76);
}

.sw-selected {
    background: rgba(245, 245, 245, 0.356);
}


