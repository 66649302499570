.session-inspiration-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  border: 1px solid #ccc;
  padding: 16px;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translate(-2px, -2px);
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translate(2px, 2px);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }

  &.active {
    border: 1px solid #000000;
  }
}

.session-inspiration-img {
  width: 100%;
  max-width: 70px;
  margin-right: 20px;
  height: auto;
}

.session-inspiration-img img {
  border-radius: 2px;
}

.session-inspiration-infos h2 {
  font-family: 'Inter';
  font-weight: 900;
  font-size: 14px;
  line-height: 1;
  text-align: left;
  margin-bottom: 5px;
}

.session-inspiration-card p {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #666;
  text-align: left;
}

.session-remove {
  position: absolute;
  top: -10px;
  right: -10px;
  background: none;
  border: none;
  cursor: pointer;
}

.remove-icon {
  color: rgb(250, 16, 90);
  border: 1px solid #ffffff;
  
  background-color: white;
  font-size: 1.3em;
}