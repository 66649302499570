.dropDownProfile {
    position: absolute;
    top: 60px;
    right: 10%;
    padding: 15px;
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: right;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    transition: all .2s;

}

.link-nav-dropDown{
    display: none;
}

//  ====================== RESPONSIVE AREA ===============================
//  ======================+++++++++++++++++===============================
@media screen and (max-width: 570px) {
    .link-nav-dropDown{
        display: block;
    }
}