.concept-toolbox-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  position: relative;
  padding-bottom: 60px;
}

.session-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  padding-bottom: 20px;
  gap: 20px;
  overflow-y: auto;
}

.variation-title{
  font-family: 'Inter';
  font-weight: 900;
  font-size: 25px;
  line-height: 25px;
  border: 1px solid black;
  padding: 15px;

  color: rgba(0, 0, 0, 0.786);
  width: 100%;
  text-align: center;
}

.variation-btn-panel{
  position: absolute;
  width: 100%;
  padding: 15px;
  bottom: 0;
  background: white;
}

.variation-button {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  color: #ff1755;
  padding: 10px 20px;
  border: 1px solid #ff1755;
  border-radius: 2px;
  cursor: pointer;
  background-color: #00000000;
  transition: background-color 0.3s, color 0.3s;
}

.variation-button:hover {
  color: white;
  background-color: #ff1755;
}

