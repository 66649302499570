.box-link-questions {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #cacaca;
    transition: border-color 500ms ease;
    opacity: 0;
    animation: fadeIn 2s forwards;
}

.box-link-questions:hover {
    border-color: black;
}

.box-link-questions:hover .shape-horizontal-bar,
.box-link-questions:hover .shape-vertical-bar {
    background-color: black; // Mettez la couleur que vous voulez
}

.box-link-questions:hover .txt-beginning {
    color: black; // Mettez la couleur que vous voulez
}


.cross-shape-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    width: 30px;
    height: 30px;
    position: relative;
}

.shape-horizontal-bar {
    width: 25px;
    height: 1px;
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    background-color: #b0b0b0;
    transition: background-color 500ms ease, color 500ms ease;
}

.shape-vertical-bar {
    width: 1px;
    height: 25px;
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    background-color: #b0b0b0;
    transition: background-color 500ms ease, color 500ms ease;
}

.txt-beginning {
    font-family: 'Inter';
    font-weight: 300;
    color: #cacaca;
    text-transform: uppercase;
    transition: background-color 500ms ease, color 500ms ease;
}

// ============ KEYFRAME =================
//========================================
@keyframes fadeIn {
    to {
        opacity: 1; // L'élément deviendra complètement opaque
    }
}