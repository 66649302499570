.desc-container {
    width: 100%;
    
}

.desc-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
    height: 90%;
    background-color: white;
    overflow: hidden;

}

.desc-panel:hover {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.05);
}

.title-archetypes-descr {
    font-family: inter;
    font-weight: 900;
    font-size: 55px;
    line-height: 35px;
    color: white;
    text-transform: uppercase;
    height: 10%;

}

.txt-archetypes-descr {
    width: 80%;
    font-family: inter;
    font-weight: 500;
    font-size: 15px;
    text-align: left;
    line-height: 16px;
    color: black;
}

.title-inside-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

}

.title-desc {
    font-family: inter;
    font-weight: 900;
    font-size: 15px;
    line-height: 18px;
    color: black;
    text-transform: uppercase;
}

.archetype-desc-img {
    width: 55px;
    margin-right: 10px;

}


//RESPONSIVE PART

@media (max-width: 415px) {
    .title-archetypes-descr {
        font-size: 40px;
        line-height: 25px;
        height: auto;
    
    }
}

@media (max-width: 345px) {
    .title-archetypes-descr {
        font-size: 30px;
        line-height: 20px;
        height: auto;
    
    }
}