.payment-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: rgb(249, 249, 249);
    z-index: 1; 
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
    
    @media (max-width: 768px) {
      padding-top: 20px;

  }

}

.img-bg-payment {
    position: absolute;
    bottom: 0%;
    right: 0;
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    z-index: -1;
}

.img-bg-payment img {
    width: 80%; 
    height: auto;
}

.logo-bp {
  position: fixed; 
  left: 5%;
  top: 5%;
  padding: 15px;
  z-index: 999;
  
    @media (max-width: 1200px) {
    position: relative;  // Le logo n'est plus fixe sur mobile
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-bottom: 10px;
  }
}
.logo-bp img {
  width: 150px;
  height: auto;
}



