.flex-container {
    width: 100%; // Définir la largeur à 100% pour qu'il s'adapte à la largeur du parent
    height: 50px;
    display: flex;
    gap: 0; // Utilisez le pourcentage pour l'espacement
    border: 1px solid black;
}

.button {
    flex: 1;
    position: relative;
    border-radius: 1px;
    padding: 15px 2%; // Utilisez le pourcentage pour le padding

    font-family: inter;
    font-weight: 900;
    font-size: 12px;
    color: rgb(0, 0, 0);

    outline-color: rgb(255, 255, 255);
    transition: all 0.3s ease;
    background-color: transparent;
    border: none;
    text-transform: uppercase;
}




.button:focus {

    outline-offset: 2px;
}

.button:hover {
    //color: rgba(255, 255, 255, 0.6);
}

.activeButton {
    background-color: #000000;
    /* Couleur de fond pour le bouton actif */
    color: white;
    /* Couleur du texte pour le bouton actif */
}

.hoverButton {
    /* No specific style for hoverButton based on the original code */
}

.bubble {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0);
    /* Utilisez une couleur légèrement transparente */
    border-radius: 1px;
}

.txt-title-filter {
    font-family: inter;
    font-weight: 900;
    font-size: 35px;
    height: 38px;
    text-transform: uppercase;

}


//////////////////
// Responsive part
//////////////////
@media (max-width: 480px) {
    .txt-title-filter {
        font-size: 20px;
        height: 22px;    
    }

    .button {
        font-weight: 900;
        font-size: 9px;

    }
}