.inspiration-message {
    display: flex;
    flex-direction: column;
    border: 1px solid #000000;
    padding: 20px;
    max-width: 550px;
}

.inspiration-message:hover {
  box-shadow: 5px 4px 0px rgba(0, 0, 0, 0.051);
}

.inspiration-message-title{
  font-family: 'Inter';
  font-weight: 800;
  font-size: 16px;
}

.inspiration-image {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
  border-radius: 5px;
}

.inspiration-tags {
    display: flex;
    margin-top: 10px;
    gap: 5px;
}

.tag {
  padding: 2px 10px;
  border-radius: 2px;
  font-size: 12px;
  color: white;
}

.archetype {
  background-color: #ff2049;
}

.content-type {
  background-color: #f5d357;
}

.level {
  background-color: #44f6d8; 
}