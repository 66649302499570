.inspiration-post {
    width: 380px;
    height: 380px;
    margin-bottom: 20px;
}

.inspiration-archetype-title {
    font-family: Inter;
    font-weight: 900;
    font-size: 35px;
    height: 38px;
    text-transform: uppercase;
    text-align: left;
}

.inspiration-post-background {
    position: relative;
    width: 100%;
    height: 100%;

    border: 1px solid black;
    /* Ajoute une bordure de 1 pixel en noir */
}

.shape-gradiant {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 35%, black 95%);
}

.inspiration-img {
    position: absolute;
    z-index: 1;
    width: 100%;
}

.inspiration-info-panel {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    align-items: flex-end;
    
    z-index: 3;
    top: auto;
    bottom: 0;

    min-height: 50px;
    width: 100%;
}

.inspiration-shape {
    position: relative;
    bottom: -3px;
    left: -3px;
    min-height: 50px;
    max-height: 50px;
    width: 30%;
    background-color: #f8f8f8;
}

.inpiration-title-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 100%;
    max-width: 290px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.inspiration-title {
    font-family: Inter;
    font-weight: 900;
    font-size: 20px;
    line-height: 1;

    color: white;
    text-align: left;
    padding-left: 20px;
}

.inspiration-tag-level {
    font-family: Inter;
    font-weight: 900;
    font-size: 10px;
    color: white;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50px;
    left: auto;
    right: -30px;
    bottom: auto;

    z-index: 4;
    width: 150px;
    height: 35px;
    background-color: #F70F4E;

    border-radius: 2px;

}