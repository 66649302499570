.model-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.model-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  width: 100px;
  height: 110px;
  border-radius: 1px;
  border: 1px solid #e2e2e200;
}

.model-panel:hover {
  background-color: #f6f6f673;
  border: 1px solid #e2e2e2;
}

.selected-model {
  background-color: #f6f6f673;
  border: 1px solid #e2e2e2;
}

.model-image {
  width: 60px;
  height: 60px;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
}

.model-label {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  line-height: 1.0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}