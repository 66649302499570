.content-container {
    padding: 40px;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.content-container > * {
    width: 100%;          
    max-width: 1720px;    
    margin: 0 auto;       
}

.content-filter-panel {
    display: grid;
    grid-template-columns: 45% 30%;
    gap: 30px;
    
    margin-bottom: 60px; 
}

.content-posts-panel {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-gap: 10px;
    
    justify-items: center;
    align-items: left;
    overflow: hidden;

}

.btn-panel-loading {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

    .btn-load {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: black;
        font: 'Inter';
        color: white;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 2%;

    }
}



//////////////////
// Responsive part
//////////////////
@media (max-width: 1300px) {
    .content-filter-panel {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        gap: 10px;
    }

    .content-posts-panel {
        grid-template-columns: 1fr 1fr;
        justify-items: center;
    }
    

}

@media (max-width: 740px) {
    .content-container {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .content-posts-panel {
        grid-template-columns: 1fr;
        justify-items: center;
    }

}