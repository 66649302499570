.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(7, 4, 10, 0.871);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-concept-img{
    width: 150px;
    border-radius: 3px;
    margin-bottom: 20px;
}

.popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0.75px solid #ffffff;

  background: rgba(5, 1, 9, 0.44);
  padding: 40px;
  padding-top: 60px;
  padding-bottom: 60px;
  border-radius: 1px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  z-index: 1001;
  box-shadow: 10px 10px 0px  rgba(0, 0, 0, 0.306);

}

.popup-content h2 {
 font-family: 'Inter';
  font-weight: 800;
  font-size: 18px;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 10px;
  }

.popup-content p {
  margin-bottom: 20px;
  color: #ffffff;
}

.button-send {
  border: 1px solid #ffffff;
  background-color: #ffffff;
  border-radius: 2px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 25px;
  color: rgb(0, 0, 0);
  font-family: 'Inter';
  font-weight: 800;
  font-size: 14px;
  transition: transform 0.3s ease;
   

}

.button-send:hover{
  transform: translate(-2px, 
   -2px);
  box-shadow: 5px 5px 0px rgba(245, 245, 245, 0.25);
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  border: 0.25px solid rgb(255, 255, 255); 
  color: white;
  padding: 5px;
  background: transparent;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background-color: #eaeaea65;
}