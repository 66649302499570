.archetype-block {
    padding: 20px;
    width: 100%;
    height: 100%; 

    overflow: auto;
}

.archetype-block > * {
    width: 100%;          
    max-width: 1720px;    
    margin: 0 auto;       
}

.archetypes-panel-profile {
    width: 100%;
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    background-color: white;
    overflow: auto;
}

.archetypes-panel-infos {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 20px;
    width: 100%;
    min-height: 400px;
}

.inner-panel {
    display: inline-flex;
}

.archetypes-container {
    margin-right: 60px;
    margin-left: 60px;
    width: 250px;
    cursor: pointer;

}

.archetype-container--disabled {
    filter: grayscale(100%);
    cursor: not-allowed;
}

.archetype-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 250px;
    height: 350px;
    border: 1px solid black;
    overflow: hidden;

}


.archetype-card-not-selected {
    border-color: #d8d8d8;
}


.archetype-img {
    position: relative;
    bottom: -10px;
    right: 5px;
    width: 130%;
    z-index: 2;
}


.shape-archetype-circle {
    position: absolute;
    z-index: 1;
    right: 10px;
    width: 250px;
    height: 250px;
    border-radius: 1000px;
    background-color: #ff1044;
}

.archetype-circle-not-selected {
    background-color: #d8d8d8;
}

.archetype-title {
    position: relative;
    z-index: 5;
    font-family: inter;
    font-weight: 900;
    font-size: 30px;
    line-height: 20px;
    text-align: right;
    color: black;
    text-transform: uppercase;
    width: 100%;
}

.affinity-bar {
    margin-top: 10px;
    width: 100%;
    height: 10px;
    border: 1px solid black;

}

.affinity-hide {
    display: none;
}

.progress-bar {
    width: 30%;
    height: 100%;
    background-color: black;
}

.txt-archetypes-affinity {
    font-family: inter;
    font-weight: 900;
    font-size: 20px;
    line-height: 15px;
    color: black;

}

.archetypes-wrapper-video{
    margin-bottom: 20px;
}

.video-title{
    font-family: inter;
    font-weight: 900;
    font-size: 55px;
    line-height: 35px;
    color: white;
    text-transform: uppercase;
    height: 10%;
}

.video-panel{
    padding: 40px;
    background-color: white;
}


//RESPONSIVE PART

@media (max-width: 991px) {
    .archetypes-panel-infos {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: flex-start;
    }
}

@media (max-width: 480px) {
}