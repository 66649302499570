.panel-title {
    margin-bottom: 100px;
}

.favoris-title {

    font: 'Inter black';
    color: rgb(0, 0, 0);
    font-size: 80px;
    font-weight: 900;
    height: 55px;
    text-transform: uppercase;
}


//  ====================== RESPONSIVE AREA ===============================
//  ======================+++++++++++++++++===============================

@media screen and (min-width: 1920px) {}

@media screen and (min-width: 1440px) {

}

@media screen and (max-width: 991px) {



}

@media screen and (max-width: 767px) {
    .favoris-title {
        font-size: 45px;
        height: 30px;
        line-height: 30px;
    }
}

@media screen and (max-width: 479px) {
    .favoris-title {
        font-size: 40px;
        height: 25px;
        line-height: 25px;
    }
}