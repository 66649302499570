.concepts-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  
}

.concept-message {
  display: flex;
  flex-direction: column;
  border: 1px solid #000000;
  padding: 40px;
  max-width: 90%;
  border-radius: 2px;

}

.concept-message:hover {
  box-shadow: 5px 4px 0px rgba(0, 0, 0, 0.051);
}

.concept-message-title-panel{
  display: flex ;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
}

.concept-message-icon {
  font-size: 30px;
  padding: 5px;
  border: 1px solid #9d9d9d;
  border-radius: 2px;
  margin-right: 10px;
  color: #d8d8d8;
}

.concept-title {
  font-family: 'Inter';
  font-weight: 800;
  font-size: 16px;
  line-height: 1;
  color: #1b1b1b;
  padding: 5px;
}

.concept-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;
}