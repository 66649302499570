.react-dropdown-select {
    height: auto !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    min-width: 300px !important;
    min-height: 50px !important;
    border-color: black !important;
    border-radius: 0px !important;
}

.react-dropdown-select-input {
    color: #fff;
    font-weight: 300;
    font-size: 9px;
    text-transform: uppercase !important;

}

.react-dropdown-select-option-label {
    font-family: Inter !important;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase !important;

}


.react-dropdown-select-item {
    // Remplacez 'YourFontFamily' par la police que vous voulez utiliser pour les options
    font-family: Inter !important;
    font-weight: 400;
    font-size: 11px;
    text-transform: uppercase !important;

}

.react-dropdown-select-item.react-dropdown-select-item-active {
    //background: #111;

    color: #f31818 !important;
}

.react-dropdown-select-item.react-dropdown-select-item-selected {
    //background: #111;
    border-bottom: 1px solid #000000 !important;
    font-weight: bold;

}

.react-dropdown-select-dropdown {
    position: absolute;
    left: 0;
    border: none;
    max-width: 400px !important;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 0px !important;
    max-height: 300px;
    overflow: auto;
    z-index: 9;
    background: #333;
    box-shadow: none !important;
}


//////////////////
// Responsive part
//////////////////
@media screen and (max-width: 480px) {
    .react-dropdown-select {
        min-width: 40px !important;
    }

}