.coach-custom {
  padding: 10px;
  padding-bottom: 0px;
}

.coach-grid-structure {
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  height: 100%;
  grid-template-columns: 1fr 400px;
  gap: 15px;
}

.chat-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px;
  height: 100%;
  border: 1px solid #e2e2e2;
  background-color: white;
  overflow: hidden; 
}

.chat-content-panel {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 75px;
}

.chat-message-role {
  display: flex;
  justify-content: flex-start;
  
}

.user-message {
  justify-content: flex-end;
}

.chat-message-content {
  margin-bottom: 20px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 10px;
  border-radius: 3px;
}

.user-message .chat-message-content {
  background-color: #00000006;
  padding:10px;
  color: rgb(0, 0, 0);
  text-align: left;
  max-width: 450px;
}

.system-message .chat-message-content {
  max-width: 100%;
  color: black;
  text-align: left;
}

.system-message-container {
  display: flex;
  align-items: flex-start;
}

.loading-container{
  display: flex;
  justify-content: left;
  align-items: center;
}

.system-message-icon {
  width: 25px; 
  height: auto;
  margin-right: 15px;
  padding: 5px;
  border-radius: 2px;
  border: 1px solid #000000;
  flex-shrink: 0;
}

.enter-message-icon {
  width: 15px; 
  flex-shrink: 0;
}


.chat-input-wrapper {
  position: absolute;
  padding: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}

.chat-input-box {
  display: flex;
  align-items: flex-end;
  padding: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  border: 1px solid #000000;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.chat-input {
  flex: 1;
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  font-family: 'Arial';
  font-weight: 400;
  color: rgb(0, 0, 0);
  font-size: 16px;
  border-radius: 0px;
  outline: none !important;
  overflow-y: auto;
  resize: none;
}

.send-button {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 2px;
  margin-left: 15px;
  cursor: pointer;
  background-color: #000000;
}

.send-button:hover {
  background-color: #000000;
}

.chat-input:disabled,
.send-button:disabled {
  background-color: #f0f0f0; // Gris clair pour l'arrière-plan
  color: #a0a0a0;           // Gris pour le texte
  cursor: not-allowed;      // Curseur indiquant l'impossibilité d'interagir
  opacity: 0.6;             // Rend l'élément visuellement estompé
}

.tool-container{
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  height: 100%;
  grid-template-rows: 300px 1fr;
  gap: 20px;
  overflow: hidden;

}

.coach-model-wrapper {
  padding: 20px;
  background-color: white;
  border: 1px solid #e2e2e2;
  flex: 0 0 300px;
}

.coach-options-wrapper {
  position: relative;
  background-color: white;
  border: 1px solid #e2e2e2;
  flex: 1;
  height: 100%;
  overflow: hidden;
  
}

.loading-circle {
  border: 1px solid #000000;
}

.system-message-error{
  color: rgb(244, 17, 62);
}

///

/* Style pour le curseur de frappe */
.typing-cursor {
  display: inline-block;
  background-color: #000;
  width: 2px;
  height: 1em;
  margin-left: 5px;
  animation: blink 1s steps(2, start) infinite;
}

.typing-cursor::after {
  content: "";
  display: inline-block;
  background-color: #000;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin-left: 5px;
  animation: blink 1s steps(2, start) infinite;
}

.system-text-streaming {
  display: flex;
  flex-direction: column; /* Ajout explicite de flex-direction: row */
 justify-content: left;
   border: 1px solid #e4e4e47c;
   border-radius: 3px;
  padding: 20px;
}

.typing-cursor {
  display: inline-block;
  background-color: #000;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  animation: blink 1s steps(2, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

/* Styles Markdown */

.system-message-container h1,
.system-message-container h2,
.system-message-container h3,
.system-message-container h5,
.system-message-container h6 {
  color: #000000 !important;
  margin: 0px 0px 0px 0px !important;


}

.system-message-container h3 {
  font-weight: 800;
  color: #000000 !important;
  font-size: 18px;
  padding: 0px !important; 
  margin-top: 10px !important;
  height: auto !important;
}

.system-message-container h4 {
  color: #000000 !important;
  margin: 0px 0px 0px 0px !important;
  font-weight: 700;

  font-size: 16px;
  padding: 0px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.system-message-container p {
  margin: 0 !important;
  padding: 0 !important; 
  margin-bottom: 5px !important;
}

.system-message-container a {
  color: #f30e53 !important;
  text-decoration: none !important;
}

.system-message-container a:hover {
  text-decoration: underline !important;
}


.system-message-container ol {
  list-style: none !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  padding-bottom: 20px !important;
  margin: 0px 0 !important;
  margin-bottom: 20px !important;
  margin-right: 50px;
  margin-left: 25px !important;
  background-color: #f6f6f6;
  counter-reset: item !important;
}

.system-message-container ol > li {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 0px 50px !important; 
  position: relative;
  counter-increment: item !important;
}

.system-message-container ol > li::before {
  content: counter(item) !important;
  display: inline-block !important;
  background-color: #ffffff !important;
  font-weight: 600;
  color: #000000 !important;
  border-radius: 50% !important;
  margin: 0px !important;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  text-align: center !important;
  font-size: 12px !important;
  position: absolute !important;
  left: -5%;
}

.system-message-container ul {
  list-style-type: disc !important; 
  padding: 0px !important;
  margin: 0px 0 !important;
  margin-left: 10px !important;
}

.system-message-container ul > li {
  margin: 0 0 0px 30px !important;
  width: 90% !important;
  position: relative;
}

.system-message-container ul > li::before {
  display: inline-block !important;
  margin: 10px !important;

  width: 5px !important;
  height: 5px !important;
  background-color: #000 !important;
  content: '' !important;
    vertical-align: middle !important;

}

.system-message-container ol ol {
  counter-reset: item !important;
}

.system-message-container code {
  background-color: #F1F3F4 !important;
  border-radius: 4px !important;
  padding: 2px 4px !important;
  font-family: 'Courier New', Courier, monospace !important;
}

.system-message-container pre {
  background-color: #F1F3F4 !important;
  border-radius: 8px !important;
  padding: 10px !important;
  overflow-x: auto !important;
}


.system-message-container blockquote {
  border-left: 4px solid #1A73E8 !important;
  padding-left: 10px !important;
  color: #666 !important;
  margin: 5px 0 !important;
  padding: 0 !important; 
}
@media (min-width: 2000px) {
.tool-container{
  grid-template-rows: 400px 1fr;
  
}
}