.navbar-courses {
    position: fixed;
    z-index: 99;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;

    width: 100%;
    height: 60px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-left: 15px;

    border-bottom: 1px solid hsla(0, 0.00%, 88.59%, 1.00);

    background-color: hsla(0, 0.00%, 100.00%, 1.00);
}

.contener-courses {
    display: flex;
    height: 100%;
    justify-content: space-between;
}

.nav-menu-courses {
    display: flex;
    width: auto;
    height: 100%;
    justify-content: center;
    align-items: center;
}


.brand-2 {
    width: 70px;
    height: 70px;
    background-color: #000;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    bottom: -18px;
    border: 0.075px solid rgb(255, 255, 255); /* Ajout de la bordure rouge */
}


.image-75 {
    width: 30px;
    height: 30px;
    max-width: none;
    background-color: transparent;
}


.list-nav-courses {
    display: flex;
    height: 100%;
    padding-left: 0px;
    background-color: transparent;
    list-style-type: none;
}

.icon-nav-courses{
    margin-right: 14px;
}

.list-item-courses {
    display: flex;
    height: 100%;
    margin-left: 17px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
}


.link-nav-courses {
    display: flex;
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.link-nav-courses:hover {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    border-radius: 5px;
    background-color: #f8f8f8;
}

.txt-nav-link {
    margin-left: 0px;
    clear: none;

    background-color: transparent;

    font-family: Inter;
    color: black;
    font-size: 10px;
    line-height: 14px;
    font-weight: 700;
    text-align: left;
    letter-spacing: -0.08px;
    text-transform: uppercase;
}


.txt-nav-link.white {
    color: white;
}



.list-item-8 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.div-block-134 {
    display: flex;
    width: auto;
    margin-top: 0px;
    margin-right: 20px;
}


.text-block-76 {
    margin-right: 5px;
    color: black;
    font-size: 16px;
    font-weight: 700;
}

.image-74 {
    margin-left: 5px;
}

.dropdown-wrapper {
    background-color: black;
}

.dropdown-toggle {
    padding: 20px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.icon-container {
    background-image: url('https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/643935b83f3597f4629bdfd7_1077114.png'); 
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 11px; 
    height: 11px; 
    margin-right: 15px;
}


//  ====================== RESPONSIVE AREA ===============================
//  ======================+++++++++++++++++===============================
@media screen and (min-width: 1920px) {
    .txt-nav-link {
        font-size: 11px;
        line-height: 11px;
    }
}

@media screen and (min-width: 1440px) {
    .txt-nav-link {
        font-size: 11px;
    }
}
@media screen and (max-width: 991px) {
    .nav-menu-courses {
        display: flex;
        width: auto;
        justify-content: flex-start;
    }

    .brand-2 {
        width: 55px;
        height: 55px;
    }

    .div-block-134 {
        display: none;
    }

    .txt-nav-link {
        margin-left: 11px;
        font-size: 9px;
    } 
    .dropdown-toggle {
        padding-right: 25px;
        padding-left: 25px;
    }
    .image-75 {
        width: 20px;
        height: 20px;
    }
    
    .icon-container {
        margin: 0px;
    }
}

@media screen and (max-width: 767px) {
    .nav-menu-courses {
        width: auto;
    }

    .brand-2 {
        padding-left: 0px;
    }

    .txt-nav-link.white {
        display: none;
    }
}

@media screen and (max-width: 570px) {
    .icon-container {
        width: 18px; 
        height: 18px; 
        background-image: url('https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/5e5cfad37cde6be075b5cd83_icon-menu.svg');        
    }
    
    .list-item-courses {
        display: none;
    }
}