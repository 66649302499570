.inspiration{
    width: 100%;
    flex: 0 0 auto;
    overflow-y: scroll;

}

.inspiration-banner {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-bottom: 40px;
    width: 100%;
    height: 300px;
    background-color: rgb(18, 7, 27);
    position: relative;
    overflow: hidden;
}

.icon-banner {
    width: 300px;
    height: 300px;
    position: absolute;
    top: -15%;
    left: 5%;
}

.inpiration-title {
    height: 55px;
    font: 'Inter';
    color: white;
    font-size: 50px;
    font-weight: 900;
    text-transform: uppercase;
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
}

.inspiration-title-panel {
    height: auto;
    width: 50%;
    max-width: 550px;
    padding: 30px;
    position: relative;
}

.inspiration-desc {
    font: 'Inter';
    color: white;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    line-height: 1.1;
    padding: 15px;
    
    position: relative;
    z-index: 1;

    border: 0.75px solid white;
    background-color: rgba(0, 0, 0, 0.323);
}

.inspiration-wrapper{
    position: relative;
}


.inspiration-filter-panel {
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 50px;
    margin-bottom: 60px;
    width: 100%;
}

.inspiration-posts-panel {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 45px;
    justify-items: center;
    margin-bottom: 20px;
    }

.icon-banner img {
    width: 100%;
    max-width: 500px;
    height: auto;

}


/////////////////////////////////
// Responsive part
/////////////////////////////////
@media (max-width: 1450px) {
    .inspiration-filter-panel {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        gap: 10px;
    }
}


@media (max-width: 1080px) {
    .inspiration-posts-panel {
        grid-template-columns: 1fr;
        justify-items: center;
    }
    
    .inspiration-title-panel{
        width: 65%;
    }
    
    .inspiration-desc {
        font-size: 16px;
    }
    
    .icon-banner {
        display: flex;
 
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        left:auto;
        right: auto;
    }
    
    .icon-banner img {
        max-width: 190px;
    }
    
    .inpiration-title {
        font-size: 30px;
        height: 33px;
            top: auto;
    bottom: 0;
    left: auto;
    right: auto;
    }

}

