.section-app {
  margin-top: 60px;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden;
}

.grid-app {
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  height: 100%;
  grid-template-columns: 350px 1fr;
  gap: 0;
}

.nav-side-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start;

  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: auto;
  border-right: 1px solid #e2e2e2;
  overflow:visible; 
  background-color: rgb(255, 255, 255);

}

.brand-title {
  font-family: 'Inter';
  font-weight: 900;
  color: #f4f4f4;
  font-size: 45px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  
}

.content-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  padding-bottom: 20px;
  overflow: auto;
}

.btn-mobile-panel {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 90%;
  height: 50px;
  margin-top: 10px;
}

.btn-mobile-side-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  border-radius: 1px;
  background-color: black;
}

.btn-mobile-menu-title {
  font-family: 'Inter';
  font-weight: 900;
  font-size: 15px;
  line-height: 40px;
  text-transform: uppercase;
  margin-left: 5px;
  color: rgb(255, 255, 255);
}

.reduce-nav-side-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-left: 0.75px solid #e2e2e2;
  border-top: 0.75px solid #e2e2e2;
  border-bottom: 0.75px solid #e2e2e2;
  background-color: rgb(255, 255, 255);
  transition: background-color 0.3s, color 0.3s, width 0.3s;
}

.reduce-nav-side-menu.full-width {
  width: 100%; 
}


// ====================== RESPONSIVE AREA ===============================
// ======================+++++++++++++++++===============================

@media screen and (max-width: 479px) {}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 991px) {
  .brand-title {
    font-size: 25px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .brand-title.collapsed {
    display: none;
  }

  .grid-app {
    grid-template-columns: 1fr !important;
    grid-template-rows: auto 1fr;
  }

  .nav-side-menu {
    height: 100%;
    padding: 0px;
    margin: 0px;
    margin-bottom: 20px;
    overflow: visible;
    border-bottom: 0px;
    background-color: transparent;
  }

  .btn-mobile-side-menu {
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 25px;
    padding-right: 25px;
  }
  
  .reduce-nav-side-menu{
    visibility: hidden;
  }
  
  .test{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
}

@media screen and (min-width: 991px) {
  .btn-mobile-panel {
    display: none;
  }
}

@media screen and (min-width: 1440px) {


}

@media screen and (min-width: 1920px) {

}

@media screen and (min-width: 2100px) {



}
