.color-label {
    color: rgba(255, 15, 79, 0.929);
    margin-bottom: 7px;

}

.buttons-list-text {
    font: 'Inter';
    font-weight: 700;
}

.btn-list-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

@media (max-width: 640px) {
    
    .btn-list-container {
    display: flex;
    flex-direction: column;
    
    }

}